export default class HomeModalcontroller {
    constructor(FitmentCenterService, customer) {
        this.FitmentCenterService = FitmentCenterService;
        this.postcode = "";        
        this.selectdealerrecordName = "";
        this.sendMessageSucc = false;
        this.sendMessageError = false;
        this.fitmentCenter = {};
        this.deliveryAddressList = null;
        this.showresult = false;
        this.sendMessageErrorMess = "";  //Error!!! Please try again.
        this.customerText = customer.text;
    }
    selectdealer(e) {
        console.log(e);
    }
    updatedealer() {
        if (!this.selectdealerrecordid) {
            this.sendMessageErrorMess = 'Please select a fitment centre!';
            return;
        }
        this.FitmentCenterService.changeCustomerAPi(this.selectdealerrecordid).then((successRes) => {
            this.deliveryAddressList = successRes;
            localStorage.setItem("home_modalInfo", "true");
            window.location.reload();
        }, (errorRes) => {
            this.sendMessageError = true;
            if (errorRes.statusText !== "") {
                this.sendMessageErrorMess = errorRes.statusText;
            }
        });
    }
    submit() {
        this.FitmentCenterService.getFitmentCenterDetails(this.postcode).then((successRes) => {
            this.deliveryAddressList = successRes;
            this.showresult = true;

            this.sendMessageSucc = true;
        }, (errorRes) => {
            this.sendMessageError = true;
            if (errorRes.statusText !== "") {
                this.sendMessageErrorMess = errorRes.statusText;
            }
        });
    }
}