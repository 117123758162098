export default class ReleaseOrderController {
  constructor($uibModal){
      this.$uibModal = $uibModal;
  }
    confirmReleaseOrder(recordId, orderNumber, moveToCart) {
        const modalInstance = this.$uibModal.open({
            templateUrl: 'ReleaseOrderConfirmModal.html',
            controller: 'ReleaseOrderModalController',
            controllerAs: 'ReleaseOrderModalCtrl',
            size: 'md',
            windowClass: 'modal-cancelOrder',
            resolve: {
                orderInfo: function () {
                    return { recordId: recordId, orderNumber: orderNumber, moveToCart: moveToCart };
                }
                //recordId: function () { return recordId; },
                //orderNumber: function () { return orderNumber; }
            }
        });
      
        modalInstance.result.then(function (selectedItem) {
            console.log(selectedItem);
        }, function () {
            console.log('Modal dismissed at: ' + new Date());
        });
    }

    cancelReleaseOrder(recordId, orderNumber, moveToCart) {
        const modalInstance = this.$uibModal.open({
            templateUrl: 'CancelReleaseOrderConfirmModal.html',
            controller: 'ReleaseOrderModalController',
            controllerAs: 'ReleaseOrderModalCtrl',
            size: 'md',
            windowClass: 'modal-cancelOrder',
            resolve: {
                orderInfo: function () {
                    return { recordId: recordId, orderNumber: orderNumber, moveToCart: moveToCart };
                }
                //recordId: function () { return recordId; },
                //orderNumber: function () { return orderNumber; }
            }
        });

        modalInstance.result.then(function (selectedItem) {
            console.log(selectedItem);
        }, function () {
            console.log('Modal dismissed at: ' + new Date());
        });
    }


};