export default class TargetService {
  constructor($http, $q) {
    this.$http = $http;
    this.$q = $q;
  }

    getTargetData(productLine) {
    const reqObj = {
        "productLine": productLine
    };
        console.info("Send to /target/TargetsData data", reqObj);
    return this.$q((resolve, reject) => {
        this.$http.post("/target/TargetsData", reqObj).then(
        response => {
            console.info("Response from /target/TargetsData", reqObj, response);
            let resultArray = response.data.TargetList.map(data => {
                return {
                    productLine: data.LineNo,
                    description: data.Description,
                    units: data.Units,
                    rebate: data.RebatePercent,
                    potentialRebate: data.PotentialRebate,
                    actualSpend: data.ActualSpend,
                    averageSpendPerUnit: data.AverageSpendPerUnit
                };
            });
            let result = {
                targetList: resultArray,
                isTargetReached: response.data.IsTargetReached
            };
            console.log(response);
            resolve(result);
          // TODO: Need to use the real data
          //resolve([
          //  {
          //    productLine: 'Target to reach',
          //    units: '175',
          //    rebate: '5.00',
          //    potentialRebate: '875.00',
          //    actualSpend: '17,500',
          //    averageSpendPerUnit: '100.00'
          //  }
          //]);
        }
        ,
        response => {
          console.error("Response from /report", reqObj, response);
          reject(response);
        });
    });
  }
}