import bse from 'bootstrap-enhancement';
// Fix font awesome 5's loading and shifting issue
bse.utilities.addStyles(`
    body {
  display: none !important;
}

.fontawesome-i2svg-active body {
  display: flex !important;
}
  `);
// Inject fontawesome
import fontawesome from "@fortawesome/fontawesome";
import solid from '@fortawesome/fontawesome-free-solid';
import regular from "@fortawesome/fontawesome-free-regular";
// Add the icon to the library so you can use it in your page
fontawesome.library.add(solid);
fontawesome.library.add(regular);
