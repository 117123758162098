// Inject fundamental libs
import _ from "lodash";
//import numeral from "numeral";
//import * as moment from 'moment';

// Inject jQuery related libs
import $ from "jquery";

import "jquery-match-height";
//import "parsleyjs";
import "floatthead";

// Inject bootstrap related libs
import "bootstrap";
import "bootstrap-datepicker";

// Inject customized libs
//import AOS from 'aos';
import bse from 'bootstrap-enhancement';

// Inject angular
import "./angularjs/app";

// Attach jQuery to window
window.jQuery = $;
window.$ = $;
import "jquery-validation";
import "jquery-validation-unobtrusive";

// AOS
//$(() => {
//  AOS.init();
//});

// bootstrap-datepicker
$(() => {
  $('.datepicker').datepicker({
    format: "dd/mm/yyyy"
  }
  );
});

// floathead
$(() => {
  $(".table-float-head").floatThead({
    responsiveContainer: function($table){
      return $table.closest('.table-responsive');
      },
      autoReflow: true
  });
  $(window).resize(function () {
    if (bse.utilities.checkBreakPoint.is(">=lg")) {
      $(".table-float-head").floatThead({
        responsiveContainer: function($table){
          return $table.closest('.table-responsive');
        }
      });
    }
  });
});

// bootstrap-enhancement
$(() => {
  bse.addScrollMessToResTable.init();
  bse.fixModalShifting.init();
  bse.toggleCollapseChevron.init();
  bse.scrollToTop.init(`<span class="fa-stack fa-lg">
      <i class="fa fa-circle fa-stack-2x"></i>
      <i class="fa fa-chevron-up fa-stack-1x fa-inverse"></i>
    </span>`);
  bse.resList.init({
    className: "custom-select"
  });
});

// Below are test code, should delete them later

//console.log("DSLKJLJF");
//console.log(numeral);
//console.log(moment);

//function component() {
//  var element = document.createElement("div");

//  // Lodash, now imported by this script
//  element.innerHTML = _.join(["Hello", "webpack"], " ");
//  element.classList.add("hello");

//  return element;
//}

//window.onload = function() {
//  document.body.appendChild(component());
//};

//$(function() {
//  console.log("SDFJdK");
//  $(".match-height").matchHeight();
//});

//$(function() {
//  $(".date-picker-test").datepicker({
//    minViewMode: 1,
//    daysOfWeekDisabled: "0",
//  });

//  const msg = () => {
//    console.log("dsfdfs");
//  };

//  msg();
//});
