export default class HomeController {
    constructor(FitmentCenterService, $uibModal) {
        this.$uibModal = $uibModal;
        this.overseasStock = null;
        this.cClassStock = null;
        this.FitmentCenterService = FitmentCenterService;
    }
    openModal(flag, isCustomer) {
        if (!localStorage.getItem("home_modalInfo") || flag) {
            const modalInstance = this.$uibModal.open({
                templateUrl: '/src/templates/_fitmentCenterModalContent.html',
                controller: 'HomeModalcontroller',
                controllerAs: 'homeModalCtrl',
                backdrop: 'static',// false,
                keyboard: false,
                size: 'lg',
                windowClass: 'cus-modal-lg',
                resolve: {
                    customer: function () {
                        if (isCustomer == "True") {
                            isCustomer = true;
                        } else {
                            isCustomer = false;
                        }
                        if (isCustomer) {
                            return {
                                text: "customer"
                            }
                        } else {
                            return {
                                text: "employee"
                            }
                        }
                    }
                }
            });
        }
    }
}