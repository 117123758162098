export default class CarouselController {
  constructor(UmbracoService) {
    this.UmbracoService = UmbracoService;
    this.carouselImages = [];
  }

  init(url) {
    this.UmbracoService.getCarousel(url).then((message) => {
      this.carouselImages = message;
      console.log(this.carouselImages);
    });
  }
};