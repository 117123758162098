export default class LogOnController {
    constructor($uibModal, UmbracoService) {
        this.$uibModal = $uibModal;
        this.notificationMess = '';
        this.imageBanner = '';
        this.UmbracoService = UmbracoService;
    }
    init(baseUrl) {
        this.removeSearchParam();
        localStorage.removeItem("home_modalInfo");
        this.UmbracoService.getLogonPageMess(baseUrl).then((message) => {
            this.notificationMess = message;
        });
        this.UmbracoService.getLogonPageImage(baseUrl).then((message) => {
            this.imageBanner = message;
        });
    }
    async removeSearchParam() {
        await this.delay(500);
        this.removeParams();
        await this.delay(500);
        this.removeParams();
        await this.delay(500);
        this.removeParams();
        await this.delay(500);
        this.removeParams();
    }

    removeParams() {
        const url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);
        if (params.has('samlloginerror')) {
            params.delete('samlloginerror');
            url.search = params.toString();
            window.history.replaceState(null, null, url)
        }
    }
    async delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }
    regOrForgotPin() {
        const modalInstance = this.$uibModal.open({
            templateUrl: 'LogOnModalContent.html',
            controller: 'LogOnModalController',
            controllerAs: 'LogOnModalCtrl',
            size: 'md',
            windowClass: 'modal-logOn'
        });

        modalInstance.result.then(function (selectedItem) {
            console.log(selectedItem);
        }, function () {
            console.log('Modal dismissed at: ' + new Date());
        });
    }
};