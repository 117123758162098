export default class InvoicesService {
  constructor($http, $q) {
    this.$http = $http;
    this.$q = $q;
  }

  getInvoicesByDate(fromDate, toDate) {
    const reqObj = {
      "fromDate": fromDate,
      "toDate": toDate,
    };
      console.info("Send to /Invoice/InvoicesByDate data", reqObj);
    return this.$q((resolve, reject) => {
        this.$http.post("/Invoice/InvoicesByDate", reqObj).then(
        function success(response) {
            console.info("Response from /Invoice/InvoicesByDate", reqObj, response);
          resolve(response);
        }
        ,
        function error(response) {
            console.error("Response from /Invoice/InvoicesByDate", reqObj, response);
          reject(response);
        });
    });
  }

  getInvoicesByInvoiceNO(invoiceNO) {
    const reqObj = {
      "invoiceNO": invoiceNO
    };
      console.info("Send to /Invoice/InvoicesByInvoiceNumber data", reqObj);
    return this.$q((resolve, reject) => {
        this.$http.post("/Invoice/InvoicesByInvoiceNumber", reqObj).then(
        function success(response) {
            console.info("Response from /Invoice/InvoicesByInvoiceNumber", reqObj, response);
          resolve(response);
        }
        ,
        function error(response) {
            console.error("Response from /Invoice/InvoicesByInvoiceNumber", reqObj, response);
          reject(response);
        });
    });
  }

  getInvoicesByInbillNO(inbillNO) {
    const reqObj = {
      "inbillNO": inbillNO
    };
      console.info("Send to /Invoice/InvoicesByInBillNo", reqObj);
    return this.$q((resolve, reject) => {
        this.$http.post("/Invoice/InvoicesByInBillNo", reqObj).then(
        function success(response) {
            console.info("Response from /Invoice/InvoicesByInBillNo", reqObj, response);
          resolve(response);
        }
        ,
        function error(response) {
            console.error("Response from /Invoice/InvoicesByInBillNo", reqObj, response);
          reject(response);
        });
    });
  }
}