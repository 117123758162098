export default class ProductService {
  constructor($http, $q) {
    this.$http = $http;
    this.$q = $q;
  }

  getProductDetails(recordId) {
    const reqUrl = "/product/details?pId=" + recordId;
    console.info("Get product details url: ", reqUrl);
    return this.$q((resolve, reject) =>
      this.$http.get(reqUrl).then(
        response => {
          console.info(`Response from ${reqUrl}`, response);
          resolve(response.data);
        })
        .catch(
        response => {
          console.error(`Response from ${reqUrl}`, response);
          reject(response);
        })
    );
  }
}