export default class NewsController {
  constructor(UmbracoService) {
    this.UmbracoService = UmbracoService;
  }

    init(baseUrl,bulletinGroup) {
        this.UmbracoService.getNews(baseUrl,bulletinGroup).then((message) => {
      this.newslist = message;
      console.log(this.newslist);
    });
  }
};