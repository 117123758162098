export default class UmbracoService {
  constructor($http, $q) {
    this.$http = $http;
    this.$q = $q;
  }

  getLogonPageMess(baseUrl) {
    return this.$q((resolve, reject) => {
        this.$http.post(baseUrl+"/umbraco/api/Notification/index").then(
        (response) => {
          console.info("Response from "+baseUrl+"/umbraco/api/Notification/index", response);
          resolve(response.data.Message);
        }
        ,
        (response) => {
            console.error("Response from " + baseUrl +"/umbraco/api/Notification/index", response);
          reject(response.Message);
        });
    });
  }

    getLogonPageImage(baseUrl) {
    return this.$q((resolve, reject) => {
        this.$http.post(baseUrl+"/umbraco/api/Notification/index").then(
        (response) => {
            console.info("Response from " + baseUrl +"/umbraco/api/Notification/index", response);
          const dummyData = "/images/placeholders/3000x500.png";
          resolve(dummyData);
        }
        ,
        (response) => {
            console.error("Response from " + baseUrl +"/umbraco/api/Notification/index", response);
          reject(response.Message);
        });
    });
  }

    getCarousel(baseUrl) {
    return this.$q((resolve, reject) => {
        this.$http.post(baseUrl).then(
        (response) => {
            console.info(`Response from ${baseUrl}`, response);
          //const dummyData = [
          //  {
          //    imageSrc: "/images/placeholders/3000x500.png",
          //    imageLink: "https://www.google.com.au/",
          //    openNewTab: true
          //  },
          //  {
          //    imageSrc: "/images/placeholders/3000x500.png",
          //    imageLink: "https://www.google.com.au/",
          //    openNewTab: false
          //  },
          //  {
          //    imageSrc: "/images/placeholders/3000x500.png",
          //    imageLink: "https://www.google.com.au/",
          //    openNewTab: false
          //  }
            //];
            var data = response.data.map(d =>  {
                return {
                    imageSrc: d.ImageSrc,
                    imageLink: d.ImageLink,
                    openNewTab: d.OpenNewTab
                }
            });
            resolve(data);
        }
        ,
        (response) => {
            console.error("Response from " + baseUrl +"/umbraco/api/Slider/News", response);
          reject(response.Message);
        });
    });
  }

    getNews(baseUrl,bulletinGroup) {
        const requestUrl = baseUrl + '/umbraco/api/news/index?customerBulletinGroup=' + bulletinGroup;
    return this.$q((resolve, reject) => {
      this.$http.post(requestUrl).then(
        (response) => {
          console.info(`Response from ${requestUrl}`, response);
          const newsListObj = response.data;
          const newsList = Object.keys(newsListObj).map((newsKey) => {
            return {
                newsTitle: newsKey.replace(/([a-z])([A-Z])/g, '$1 $2'),
                newsContents: newsListObj[newsKey]
            }
          });
          resolve(newsList);
        }
        ,
        (response) => {
          console.error(`Response from ${requestUrl}`, response);
          reject(response.Message);
        });
    });
  }
}