export default class RtsController {
    constructor() {
        this.tableCollapse = [];
    }
    uibCollapse(tableName) {
        if (this.tableCollapse === null || this.tableCollapse[tableName] === undefined)
            this.tableCollapse[tableName] = true;
        return this.tableCollapse[tableName];
    }
    collapseTable(tableName) {

        if (this.tableCollapse === null || this.tableCollapse[tableName] === undefined) {
            this.tableCollapse[tableName] = true;
        }
        else {
            this.tableCollapse[tableName] = !this.tableCollapse[tableName];
        }
     }
    reflowHeader() {
        $("table.table-float-head").floatThead('reflow');
    }
}