export default class CartService {
    constructor($http, $q) {
        this.$http = $http;
        this.$q = $q;
    }

    addToCart(productQty, recordId, price) {
        const reqObj = {
            "productId": recordId,
            "quantity": productQty,
            "regularPrice": price
        };
        console.info("Send to /Cart/AddToCart data", reqObj);
        return this.$q((resolve, reject) => {
            this.$http.post("/Cart/AddToCart", reqObj).then(
                function success(response) {
                    console.info("Response from /Cart/AddToCart data", reqObj, response);
                    resolve(response);
                }
                ,
                function error(response) {
                    console.error("Response from /Cart/AddToCart error", reqObj, response);
                    resolve(response);
                });
        });
    }

    countItemsInCart(success, error) {
        this.$http.post("/Cart/CountItemsInCart").then(response => { success(response.data.ItemsInCartCount); }, error);
    }
}