export default class SearchProductsController {
  constructor($uibModal) {
    this.$uibModal = $uibModal;
    this.overseasStock = null;
    this.cClassStock = null;
    this.wholesaleStock = null;
  }
  openModal() {
    const modalInstance = this.$uibModal.open({
      templateUrl: 'searchProductsModalContent.html',
      controller: 'SearchProductsModalController',
      controllerAs: 'SearchProductsModalCtrl',
      size: 'md',
      windowClass: 'modal-product-search'
    });

    modalInstance.result.then(function (selectedItem) {
      console.log(selectedItem);
    }, function () {
      console.log('Modal dismissed at: ' + new Date());
    });
  }
}