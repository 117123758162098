export default class CancelOrderModalController {
    constructor($uibModalInstance,orderInfo) {
       // this.$uibModalInstance = $uibModalInstance;
        console.log(orderInfo);
        this.orderInfo = orderInfo;
        this.activeSpiner = false;
    }
    cancelOrderClicked() {
        this.activeSpiner = true;
    }
 
  
};