export default class CancelOrderController {
  constructor($uibModal){
      this.$uibModal = $uibModal;
  }
    confirmCancelOrder(recordId, orderNumber, moveToCart) {
  const modalInstance = this.$uibModal.open({
      templateUrl: 'CancelOrderConfirmModal.html',
      controller: 'CancelOrderModalController',
      controllerAs: 'CancelOrderModalCtrl',
      size: 'md',
      windowClass: 'modal-cancelOrder',
      resolve: {
          orderInfo: function () {
              return { recordId: recordId, orderNumber: orderNumber, moveToCart: moveToCart };
          }
          //recordId: function () { return recordId; },
          //orderNumber: function () { return orderNumber; }
      }
        });
        modalInstance.result.then(function (selectedItem) {
            console.log(selectedItem);
        }, function () {
            console.log('Modal dismissed at: ' + new Date());
        });
 }
  
};