export default class FitmentCenterService {
    constructor($http, $q) {
        this.$http = $http;
        this.$q = $q;
    }

    getFitmentCenterDetails(postcode) {
        const reqUrl = "/Home/Search?postcode=" + postcode;
        console.info("Get profile details url: ", reqUrl);
        return this.$q((resolve, reject) =>
            this.$http.get(reqUrl).then(
                response => {
                    console.info(`Response from ${reqUrl}`, response);
                    resolve(response.data);
                })
                .catch(
                    response => {
                        console.error(`Response from ${reqUrl}`, response);
                        reject(response);
                    })
        );
    }

    changeCustomerAPi(id) {
      
        const reqUrl = "/Customer/ChangeCustomerAPi?id="+id;
        console.info("Get profile details url: ", reqUrl);
        return this.$q((resolve, reject) =>
            this.$http.get(reqUrl).then(
                response => {
                    console.info(`Response from ${reqUrl}`, response);
                    resolve(response.data);
                })
                .catch(
                    response => {
                        console.error(`Response from ${reqUrl}`, response);
                        reject(response);
                    })
        );
    }
}