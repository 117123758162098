export default class HistoryController {
    constructor($uibModal) {
        this.$uibModal = $uibModal;
    }
    async getPdf(SoNumber, demandNumber) {
        const response = await fetch("/history/getsaleorderpdf?saleOrderNum=" + SoNumber);
        if (response.status !== 200) {
            this.displayNotAvailableMessageBox(demandNumber)
            return;
        }
        try {
            var responseObject = JSON.parse(await response.text());
            if (responseObject.Status == 200) {
                let name = "PackList-" + demandNumber + "-" + new Date().toLocaleDateString();
                this.convertBytesToPdf(responseObject.Data, name);
            } else {
                this.displayNotAvailableMessageBox(demandNumber)
                return;
            }
        }
        catch (ex) {
            this.displayNotAvailableMessageBox(demandNumber)
        }
    }

    convertBytesToPdf(byteArray, name) {
        var bytes = new Uint8Array(byteArray).buffer;
        var blob = new Blob([bytes], { type: "application/pdf" });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
    }

    displayNotAvailableMessageBox(demandNumber) {
        localStorage.setItem('packingListDemandId', demandNumber);
        const modalInstance = this.$uibModal.open({
            templateUrl: 'fileNotFoundMessageBox.html',
            controller: 'HistoryController',
            controllerAs: 'HistoryCtrl',
            size: 'md',
            windowClass: 'modal-empty-cart',
        });
        modalInstance.result.then(function (selectedItem) {
            console.log(selectedItem);
        }, function () {
            console.log('Modal dismissed at: ' + new Date());
            localStorage.removeItem('packingListDemandId');
        });
    }

    getDemandId() {
        var demandId = localStorage.getItem('packingListDemandId');
        if (demandId) {
            return "The packing list for " + demandId + " was not found.";
        } else {
            return "The requested file was not found";
        }
    }
}