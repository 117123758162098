export default class ReportService {
  constructor($http, $q) {
    this.$http = $http;
    this.$q = $q;
  }

    getReportData(productLine) {
    const reqObj = {
        "productLine": productLine
    };
      console.info("Send to /Target/PurchaseTrendData data", reqObj);
    return this.$q((resolve, reject) => {
        this.$http.post("/Target/PurchaseTrendData", reqObj).then(
        response => {
            console.info("Response from /Target/PurchaseTrendData", reqObj, response);
          // TODO: Need to use the real service and always use resolve(response);
            if (productLine === 'tc') {
            resolve(response);
          } else {
            reject(response);
          }
        }
        ,
        response => {
            console.error("Response from /Target/PurchaseTrendData", reqObj, response);
          reject(response);
        });
    });
  }
}