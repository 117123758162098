export default class UpdatePINController {
  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  showModal() {
    const modalInstance = this.$uibModal.open({
      templateUrl: 'UpdatePINModalContent.html',
      controller: 'UpdatePINModalController',
      controllerAs: 'UpdatePINModalCtrl',
      size: 'lg',
      windowClass: 'modal-UpdatePIN',
      // Prevent modal from closing
      backdrop  : 'static',
      keyboard  : false
    });

    modalInstance.result.then(function (selectedItem) {
      console.log(selectedItem);
    }, function () {
      console.log('Modal dismissed at: ' + new Date());
    });
  }
};