export default class LogOnModalController {
  constructor(AccountService) {
    this.AccountService = AccountService;
    this.dealerId = "";
    this.emailAddress = "";
    this.sendMessageSucc = false;
    this.sendMessageError = false;
    this.sendMessageErrorMess =
      "You cannot retrieve your password because of an unknown issue. Please try again or call the support team.";
  }

  submit() {
    this.AccountService.registerPIN(this.dealerId, this.emailAddress).then((successRes) => {
      console.log(this.dealerId);
      console.log(this.emailAddress);
      this.sendMessageSucc = true;
    }, (errorRes) => {
      this.sendMessageError = true;
      if (errorRes.statusText !== "") {
        this.sendMessageErrorMess = errorRes.statusText;
      }
    });
  }
}