import * as moment from 'moment';
import bse from 'bootstrap-enhancement';

export default class InvoicesController {
  constructor(InvoicesService) {
    this.InvoicesService = InvoicesService;
    this.searchTypes = "date";

    this.moment = moment.default;
    this.fromDate = this.moment().format('01/MM/YYYY');
    this.toDate = this.moment().format('DD/MM/YYYY');

    this.invoices = [];

    this.showResults = false;
    this.error = false;
  }
  loadInvoices() {
    // This is against angular philosophy, but bootstrap-datepicker cannot trigger angularJs's model.
    const fromInputValue = document.getElementsByName('fromDate')[0].value;
    const toInputValue = document.getElementsByName('toDate')[0].value;
    console.log("fromInputValue", fromInputValue);
    console.log("toInputValue", toInputValue);
    const isDateValid = this.moment(fromInputValue, "DD-MM-YYYY").isBefore(this.moment(toInputValue, "DD-MM-YYYY"));
    if (isDateValid) {
      switch (this.searchTypes) {
        case "date":
          this.InvoicesService.getInvoicesByDate(fromInputValue, toInputValue).then((invoicesData) => {
            console.log(invoicesData);
            this.assignInvoiceDataToTable(invoicesData);
            bse.addScrollMessToResTable.update();
          });
          break;

        case "invoieNO":
          this.InvoicesService.getInvoicesByInvoiceNO(this.invoiceNO).then((invoicesData) => {
            this.assignInvoiceDataToTable(invoicesData);
            bse.addScrollMessToResTable.update();
          });
          break;

        case "inbillNO":
          this.InvoicesService.getInvoicesByInbillNO(this.inbillNO).then((invoicesData) => {
            this.assignInvoiceDataToTable(invoicesData);
            bse.addScrollMessToResTable.update();
          });
          break;
      }
    } else {
      this.showResults = false;
      this.error = true;
      event.preventDefault();
    }
  }

  assignInvoiceDataToTable(invoicesData) {
    this.showResults = true;
    this.error = false;
    this.invoices = invoicesData.data.map(d => {
      return {
        date: d.DateAsString,
        invoiceNO: d.Number,
        invoiceDownLoadLink: `/Invoice/ViewFile?invoiceId=${d.RecordId}`,
        invoiceBVLink: d.HasBVLink == true ? `/Invoice/ViewFile?invoiceId=${d.RecordId}` : "",
        inbillNO: d.FastTrack == "" ? "N/A" : d.FastTrack,
        type: d.Type == "I" ? 'Invoice' : d.Type = "C" ? "Credit Note" : "",
        value: d.Value,
          hasBVLink: d.HasBVLink,
          reason: d.Reason,
          rCCNRCTI: d.RCCNRCTI,
        invoiceDownloadBVLink: `/Invoice/ViewBVReport?invoiceId=${d.RecordId}`
      };
    });
  }
};