export default class PurchaseTrendController {
    constructor($route, $routeParams, ChartJs, ReportService) {
        this.$route = $route;
        this.$routeParams = $routeParams;
        this.reportType = $routeParams.reportType;
        this.ChartJs = ChartJs;
        this.ReportService = ReportService;
        this.error = false;

        if (this.reportType !== undefined) {
            //this.reportType = "tc";// $routeParams.reportType.toUpperCase().trim()=="PASSENGER"?"tc":"";
            this.reportType = $routeParams.reportType.toUpperCase().trim() == "PASSENGER" ? "tc" : "";
            this.ReportService.getReportData(this.reportType).then((reportData) => {
                console.log(reportData);
                this.labels = ["Last Year Month to Date", "Target", "Month to Date"];
                let chartData = [];
                chartData[0] = reportData.data.LastYearMonthToDate;
                chartData[1] = reportData.data.Target;
                chartData[2] = reportData.data.MonthToDate;

                //console.log(`reportType:${this.reportType}`);
                //this.series = ['Series A', 'Series B'];
                this.data = [
                    chartData
                    //[24, 59, 80],
                    //[28, 48, 40]
                ];
                var backgroundColours = ['#393939', '#f2d902','#27509b'];
                this.datasetOverride = [{ backgroundColor: [], borderColor:[] }];
                this.datasetOverride[0].backgroundColor = backgroundColours;
                this.datasetOverride[0].borderColor = backgroundColours;
                this.options = {
                    scales: {
                        xAxes: [{
                            scaleLabel: {
                                display: true,
                                labelString: reportData.data.CurrentPeriodFormatted,
                                fontStyle: 'bold'
                            },
                            categoryPercentage: 0.2
                        }],
                        yAxes: [{
                            scaleLabel: {
                                display: true,
                                labelString: 'UNITS',
                                fontStyle: 'bold'
                            }
                        }]
                    }
                }
                this.error = false;
            }, (error) => {
                this.error = true;
            });
        }
    }
};