export default class DealerSelectionController {
  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

  viewDetails(customerId) {
    const modalInstance = this.$uibModal.open({
      templateUrl: 'dealerDetailsModalContent.html',
      controller: 'ProfileController',
      controllerAs: 'ProfileCtrl',
      size: 'xl',
      windowClass: 'modal-dealer-details',
      resolve: {
        customerId: function () {
          return customerId;
        }
      }
    });

    modalInstance.result.then(function (selectedItem) {
      console.log(selectedItem);
    }, function () {
      console.log('Modal dismissed at: ' + new Date());
    });
  }
};