export default class NavCartController {
    constructor($rootScope, $timeout, CartService) {
    this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.cartService = CartService;
      this.isDropdownOpen = false;
      this.itemsInCartCount = 0;
    this.$rootScope.$on("productAddedToCart", (event, productCode) => {
      //event.preventDefault();
      //event.stopPropagation();
      //console.log(event);
      console.log(productCode);
      this.productCode = productCode;
      //let navCartDelay;
      $timeout.cancel(this.navCartDelay);
      this.navCartDelay = $timeout(() => {
        this.isDropdownOpen = false;
      },
        2000);
      this.isDropdownOpen = true;
        this.CountItemsInCart();
  });

        this.productCode = '';
        this.CountItemsInCart();
    }
    CountItemsInCart() {
        this.cartService.countItemsInCart(count => { this.itemsInCartCount = count; })
    }
};