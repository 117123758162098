import { setTimeout } from "timers";

export default class CheckoutController {
    constructor($timeout, $uibModal) {
        this.$timeout = $timeout;
        this.hideSpinner = true;
        this.disableConfirmButton = false;
        //this.hideConfirmOrderclickedMessage = true;
        this.$uibModal = $uibModal;
        this.intercompany = "";
    }
    confirmOrder() {
        if (this.reference && this.reference.length > 0 && this.reference.length <= 15) {
            this.hideSpinner = false;
            //this.hideConfirmOrderclickedMessage = false;
            var currentClass = this;
            this.$timeout(function () {
                currentClass.disableConfirmButton = true;
            }, 0);
            //console.log("Modal up");
            this.removeSavedValuesFromMemory();
            var modalInstance = this.$uibModal.open({
                //animation: $ctrl.animationsEnabled,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'placeOrderMessage.html',
                //controller: 'ModalInstanceCtrl',
                //controllerAs: '$ctrl',
                size: 'md'//,
                //appendTo: parentElem//,
                //resolve: {
                //    items: function () {
                //        return $ctrl.items;
                //    }
                //}
            });

        }
     
    }

    removeSavedValuesFromMemory() {
        localStorage.removeItem('persistantCart');
        localStorage.removeItem('persistantToggle');
        localStorage.removeItem('hasORing');
    }

};