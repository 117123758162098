export default class CustomerService {
  constructor($http, $q) {
    this.$http = $http;
    this.$q = $q;
  }

  getProfileDetails(customerId) {
    const reqUrl = "/Customer/profileInfo?customerId=" + customerId;
    console.info("Get profile details url: ", reqUrl);
    return this.$q((resolve, reject) =>
      this.$http.get(reqUrl).then(
        response => {
          console.info(`Response from ${reqUrl}`, response);
          resolve(response.data);
        })
        .catch(
        response => {
          console.error(`Response from ${reqUrl}`, response);
          reject(response);
        })
    );
  }
}