export default class TargetController {
    constructor($route, $routeParams, TargetService) {
        this.$route = $route;
        this.$routeParams = $routeParams;
        this.TargetService = TargetService;
        if ($routeParams.targetType != undefined) {
            let fullTargetType = $routeParams.targetType.toUpperCase().trim();
            this.targetType = fullTargetType == "PASSENGER" ? "TC" :
                              fullTargetType == "TRUCK" ? "PL" :
                              fullTargetType == "EARTHMOVER" ? "GC" :
                              fullTargetType == "AGRICULTURE" ? "AG" : fullTargetType;
        }
        this.targetData = [];

        if (this.targetType !== undefined) {
            this.TargetService.getTargetData(this.targetType).then((targetData) => {
                this.targetData = targetData;
                console.log(this.targetData);
            }, (error) => {
                this.error = true;
            });
        }
    }
};