export default class SearchResultController {
    constructor($rootScope, $timeout, $uibModal, CartService, ProductService) {
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.$uibModal = $uibModal;
        this.cartService = CartService;
        this.ProductService = ProductService;
        this.productQty = [];
      this.productAddedToCart = [];
        this.overseasStock = null;
        this.cClassStock = null;
        this.wholesaleStock = null;
        this.hideBuyPrice = false;
        this.hideESavingPrice = false;
        this.addToCartMessage = [];
        this.populateWareHouseStock = function (stock) {
            return stock.reduce((wareHouseStock, currentValue) => {
                if (wareHouseStock[currentValue.ProductNo] === undefined) {
                    wareHouseStock[currentValue.ProductNo] = [];
                }
                wareHouseStock[currentValue.ProductNo].push({
                    WarehouseName: currentValue.WarehouseName,
                    AvailableStock: currentValue.IsCCS == true ? "CCS" : currentValue.AvailableStock <= 10 ? currentValue.AvailableStock : '10+'
                });
                return wareHouseStock;
            }, []);
        }
    }

    //isProductQtyPositive(index) {
    //  if (this.productQty[index] > 0) {
    //    return true;
    //  }
    //  else {
    //    return false;
    //  }
    //}

    setWareHouseStock(overseasStock, cClassStock, wholesaleStock) {
        const cClassStockObject = angular.fromJson(cClassStock);
        const wholesaleStockObject = angular.fromJson(wholesaleStock);
        const overseasStockObject = angular.fromJson(overseasStock);
        this.cClassStock = this.populateWareHouseStock(cClassStockObject);
        this.wholesaleStock = this.populateWareHouseStock(wholesaleStockObject);
        this.overseasStock = this.populateWareHouseStock(overseasStockObject);
        //console.log(this.cClassStock);
        //console.log(this.wholesaleStock);
        //console.log(this.overseasStock);
    }

    isStockAvailableAnyWhere(productNo) {
        //console.log(productNo);
        //console.log(this.cClassStock);
        if (this.cClassStock[productNo] === null || this.cClassStock[productNo] === undefined)
            return false;
        else
            return true;
    }

    addToCart($event, productQty, recordId, price, productCode, qtyFree, isDiscontinued, qtyAvailableToOrder, maxQty, index) {
        $event.preventDefault();
        $event.stopPropagation();
        if (productQty > maxQty) {
            this.addToCartMessage[index] = "Maximum order of "+ maxQty +" per product allowed.";
            return;
        }
        if (isDiscontinued == true && productQty > qtyAvailableToOrder) {
            productQty = qtyAvailableToOrder;
            //this.addToCartMessage[index] = `As product has been discontinued you can only add ${qtyFree} to your cart.`;
            this.addToCartMessage[index] = `Discontinued and insufficient stock in default warehouse.`;
            console.log(this.addToCartMessage);
            return;
        }
        else if (productQty > qtyAvailableToOrder) {

            this.addToCartMessage[index] = `Insufficient stock in default warehouse.`;
        }
        else {
            this.addToCartMessage[index] = '';
        }
        this.cartService.addToCart(productQty, recordId, price).then(response => {
            if (response.status != 200) {
                this.addToCartMessage[index] = response.statusText;
            } else {
                this.$rootScope.$broadcast("productAddedToCart", productCode);
                this.productAddedToCart[index] = true;
            }
        });
    }
};