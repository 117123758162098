import angular from "angular";
import ngRoute from "angular-route";
import ngAnimate from "angular-animate";
import ngTouch from "angular-touch";
import uibootstrap from "ui-bootstrap4";
// angular-simple-logger is for angular-google-maps
import 'angular-simple-logger';
import "angular-google-maps";
// Use the latest chart.js, otherwise, angular-chart.js will import chart.js 2.3.x which has some unresolved issues
import 'chart.js';
import chartJs from 'angular-chart.js';


import CartService from "./service/Cart.service";
import ProductService from "./service/Product.service";
import InvoicesService from "./service/Invoices.service";
import AccountService from "./service/Account.service";
import UmbracoService from "./service/Umbraco.service";
import CustomerService from "./service/Customer.service";
import ReportService from "./service/Report.service";
import TargetService from "./service/Target.service";
import AdminService from "./service/Admin.service";
import FitmentCenterService from "./service/FitmentCenter.service";

import UsersController from "./controller/Users.controller";
import NavCartController from "./controller/NavCart.controller";
import SearchResultController from "./controller/SearchResult.controller";
import ProductDetailsController from "./controller/ProductDetails.controller";
import SearchProductsController from "./controller/SearchProducts.controller";
import CartController from "./controller/Cart.controller";
import SearchProductsModalController from "./controller/SearchProductsModal.controller";
import ProductDetailsModalController from "./controller/ProductDetailsModal.controller";
import EmptyCartModalController from "./controller/EmptyCartModal.controller";
import InvoicesController from "./controller/Invoices.controller";
import LogOnController from "./controller/LogOn.controller";
import LogOnModalController from "./controller/LogOnModal.controller";
import UpdatePINController from "./controller/UpdatePIN.controller";
import UpdatePINModalController from "./controller/UpdatePINModal.controller";
import CarouselController from "./controller/Carousel.controller";
import ProfileController from "./controller/Profile.controller";
import DealerSelectionController from "./controller/DealerSelection.controller";
import NewsController from "./controller/News.controller";
import PurchaseTrendController from "./controller/PurchaseTrend.controller";
import TargetController from "./controller/Target.controller";

import CancelOrderController from "./controller/CancelOrder.controller";
import CancelOrderModalController from "./controller/CancelOrderModal.controller";
import CheckoutController from "./controller/Checkout.controller";
import RtsController from "./controller/Rts.controller";
import AptToggleChevron from "./directive/toggleChevron.directive";
import breakEmailIfLong from "./filter/BreakEmailIfLong.filter";
import ReleaseOrderController from "./controller/ReleaseOrder.controller";
import ReleaseOrderModalController from "./controller/ReleaseOrderModal.controller";
import HistoryController from "./controller/History.controller";
import HistoryModalController from "./controller/HistorySearchModal.controller";
import HomeController from "./controller/Home.Controller";
import HomeModalcontroller from "./controller/HomeModal.controller";
import UserAccountModalController from "./controller/UserAccountModal.controller";


var app = angular.module("app", [ngRoute, ngAnimate, ngTouch, uibootstrap,'nemLogging', 'uiGmapgoogle-maps', chartJs]).config(
  function ($routeProvider, uiGmapGoogleMapApiProvider) {
      $routeProvider.when('/purchaseTrend/:reportType', {
      templateUrl: '/Target/purchaseTrendChart',
      controller: 'PurchaseTrendController',
      controllerAs: 'PurchaseTrendCtrl'
    }).when('/target/:targetType', {
      templateUrl: '/target/TargetTable',
      controller: 'TargetController',
      controllerAs: 'TargetCtrl'
    });

    uiGmapGoogleMapApiProvider.configure({
      key: 'AIzaSyAkYOyYpcYCRG8e3XLV0e1BBOTg1DwEGMU'
    });
  }
);

// Pass a default value to Profile controller
app.constant('customerId', '');

app.service('CartService', CartService);
app.service('ProductService', ProductService);
app.service('InvoicesService', InvoicesService);
app.service('AccountService', AccountService);
app.service('UmbracoService', UmbracoService);
app.service('CustomerService', CustomerService);
app.service('ReportService', ReportService);
app.service('TargetService', TargetService);
app.service('AdminService', AdminService);
app.service('FitmentCenterService', FitmentCenterService);


app.controller('UsersController', UsersController);
app.controller('NavCartController', NavCartController);
app.controller('SearchResultController', SearchResultController);
app.controller('ProductDetailsController', ProductDetailsController);
app.controller('SearchProductsController', SearchProductsController);
app.controller('CartController', CartController);
app.controller('SearchProductsModalController', SearchProductsModalController);
app.controller('ProductDetailsModalController', ProductDetailsModalController);
app.controller('EmptyCartModalController', EmptyCartModalController);
app.controller('InvoicesController', InvoicesController);
app.controller('LogOnController', LogOnController);
app.controller('LogOnModalController', LogOnModalController);
app.controller('UpdatePINController', UpdatePINController);
app.controller('UpdatePINModalController', UpdatePINModalController);
app.controller('CarouselController', CarouselController);
app.controller('ProfileController', ProfileController);
app.controller('DealerSelectionController', DealerSelectionController);
app.controller('NewsController', NewsController);
app.controller('PurchaseTrendController', PurchaseTrendController);
app.controller('TargetController', TargetController);
app.controller('HistoryController', HistoryController);
app.controller('CancelOrderModalController', CancelOrderModalController);
app.controller('CancelOrderController', CancelOrderController);
app.controller('CheckoutController', CheckoutController);
app.controller('RtsController', RtsController);
app.directive('aptToggleChevron', () => new AptToggleChevron());
app.filter('breakEmailIfLong', () => breakEmailIfLong);
app.controller('ReleaseOrderModalController', ReleaseOrderModalController);
app.controller('ReleaseOrderController', ReleaseOrderController);
app.controller('HistoryModalController', HistoryModalController);
app.controller('HomeController', HomeController);
app.controller('HomeModalcontroller', HomeModalcontroller);
app.controller('UserAccountModalController', UserAccountModalController);

