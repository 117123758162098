export default class UsersController {
    constructor(AdminService, $uibModal) {
        this.$uibModal = $uibModal;
        this.userRoles = "";
        this.isRep = false;
        this.isCustomer = true;
        this.adminService = AdminService;
        this.userTypeList = [];
        this.dealersError = "";
        this.dealerArray = [];
        this.whModalInstance;
        this.ShowRelatedEditByUserRole = function () {
            if (this.userRoles == "2" || this.userRoles == "8" || this.userRoles == "16")
                this.isRep = true;
            else
                this.isRep = false;

            if (this.userRoles == "4")
                this.isCustomer = true;
            else
                this.isCustomer = false;

        };
    }

    UserEditFormInit(userRoles, accounts) {
        if (userRoles == "2" || userRoles == "8" || userRoles == "16")
            this.isRep = true;
        else
            this.isRep = false;

        if (userRoles == "4")
            this.isCustomer = true;
        else
            this.isCustomer = false;
        this.loadUserTypes(userRoles);
        for (let i = 0; i < accounts.length; i++) {
            const accountNew = {
                AccountId: accounts[i].AccountId,
                AccountName: accounts[i].AccountName,
                DebtorName: accounts[i].DebtorName
            }
            this.dealerArray.push(accountNew);
        }
    }
    initUserTypes(userType) {
        this.userType = userType;
    }
    UserCreateFormInit(userRoles) {
        if (userRoles == "2" || userRoles == "8" || userRoles == "16")
            this.isRep = true;
        else
            this.isRep = false;

        if (userRoles == "4")
            this.isCustomer = true;
        else
            this.isCustomer = false;

    }
    changeRoles() {
        this.ShowRelatedEditByUserRole();
        this.loadUserTypes(this.userRoles);
    }

    loadUserTypes(userRole) {
        if (userRole != undefined) {
            this.adminService.loadUserTypes(userRole, success => {
                console.log(success);
                this.userTypeList = success;
            }, error => { console.log(error); })

        }
    }
    async searchDealers(event) {
        event.preventDefault();
        var userInputValue = document.getElementById("dealerSelectionInputValue").value;
        if (userInputValue == null || userInputValue.length < 2) {
            this.setError("Please enter a minimum of 2 characters/numbers to search.");
        } else {
            this.dealersError = "";
            const response = await fetch("/admin/SearchDealersWithDealerId", {
                method: "POST",
                body: JSON.stringify({
                    searchString: userInputValue,
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            });
            this.manageAccountsResponse(await response.json());
        }
    }

    manageAccountsResponse(accounts) {
        if (accounts.length == 0) {
            this.setError("No results found.");
        }
        else if (accounts.length == 1) {
            this.setError("");
            const accountNew = {
                AccountId: accounts[0].AccountId,
                AccountName: accounts[0].AccountName,
                DebtorName: accounts[0].DebtorName
            }
            this.addNewItem(accountNew);
            document.getElementById("invisible-refresh-button").click();
        } else {
            this.setError("");
            this.openUserSelectModal(accounts, this.dealerArray);
        }
    }
    addNewItem(account) {
        let itemExists = false;
        for (let i = 0; i < this.dealerArray.length; i++) {
            if (this.dealerArray[i].AccountName == account.AccountName) {
                itemExists = true;
            };
        }
        if (itemExists) {
                this.setError("This account has already been selected.");
        } else {
            this.dealerArray.push(account);
            this.updateAccountListSubmit();
        }
    }

    forceUpdate() {
    }

    deleteUserClicked(accountName) {
        let userArrayIndex = -1;
        for (let i = 0; i < this.dealerArray.length; i++) {
            if (this.dealerArray[i].AccountName == accountName) {
                userArrayIndex = i;
            };
        }
        if (userArrayIndex !== -1) {
            this.dealerArray.splice(userArrayIndex, 1);
            this.updateAccountListSubmit();
        }
    }

    setError(errorString) {
        document.getElementById("dealer-selection-error").innerHTML = errorString;
    }

    updateAccountListSubmit() {
        var accountListString = "";
        for (let i = 0; i < this.dealerArray.length; i++) {
            if (i == 0) {
                accountListString = this.dealerArray[i].AccountName;
            } else {
                accountListString += "," + this.dealerArray[i].AccountName;
            }
        }
        document.getElementById("account-list-json-value").value = JSON.stringify(this.dealerArray);
        document.getElementById("account-list-submit-value").value = accountListString;
    }

    openUserSelectModal(accountList, dealerArray) {
        this.whModalInstance = this.$uibModal.open({
            templateUrl: 'partialDealerMessageBox.html',
            size: 'md',
            windowClass: 'modal-empty-cart',
            controller: 'UserAccountModalController',
            controllerAs: 'UserModalCtrl',
            resolve: {
                dealerList: function () {
                    return accountList
                },
            }
        });
        this.whModalInstance.result.then(function (account) {
            let itemExists = false;
            for (let i = 0; i < dealerArray.length; i++) {
                if (dealerArray[i].AccountName == account.AccountName) {
                    itemExists = true;
                };
            }
            if (itemExists) {
                document.getElementById("dealer-selection-error").innerHTML = "This account has already been selected.";
            } else {
                const accountNew = {
                    AccountId: account.AccountId,
                    AccountName: account.AccountName,
                    DebtorName: account.DebtorName
                }
                dealerArray.push(accountNew);
                var accountListString = "";
                for (let i = 0; i < dealerArray.length; i++) {
                    if (i == 0) {
                        accountListString = dealerArray[i].AccountName;
                    } else {
                        accountListString += "," + dealerArray[i].AccountName;
                    }
                }
                try {
                    document.getElementById("account-list-json-value").value = JSON.stringify(dealerArray);
                } catch (ex) { };
                document.getElementById("account-list-submit-value").value = accountListString;
            }
        })
    }
};