export default class CartController {
    constructor($rootScope, $timeout, $uibModal, CartService, ProductService, $window) {
        this.$uibModal = $uibModal;
        this.errorMessage = [];
        this.hasOring = this.setORing();
        this.samedayToggle = false;
        this.$window = $window;
        this.samedayMap = {};
        this.oldSamedayMap = {};
        this.triggeredElements = [];
        this.freezeTrigger = false;
        this.rootScope = $rootScope;
        this.leadTime = 0;
    }

    init(hasORing, leadTime) {
        if (hasORing.toLowerCase() == "true") {
            this.hasOring = true;
        } else {
            this.hasOring = false;
        }
        try {
            this.leadTime = Number(leadTime);
        } catch (ex) {
            this.leadTime = 0;
        }
        localStorage.setItem("offsiteLt", this.leadTime);
        localStorage.setItem('hasORing', JSON.stringify(this.hasOring));
    }

    getCcpMaxMessage() {
        return localStorage.getItem('ccpOkMessage');
    }

    getLeadTime() {
        return Number(localStorage.getItem("offsiteLt"));
    }


    setORing() {
        var oRingValue = localStorage.getItem('hasORing');
        if (oRingValue == null) {
            return false;
        } else {
            if (oRingValue.toLowerCase() == "true") {
                return true;
            } else {
                return false;
            }
        }
    }

    emptyCart() {
        const modalInstance = this.$uibModal.open({
            templateUrl: 'emptyCartModalContent.html',
            controller: 'EmptyCartModalController',
            controllerAs: 'EmptyCartModalCtrl',
            size: 'md',
            windowClass: 'modal-empty-cart'
        });
        modalInstance.result.then(function (selectedItem) {
            console.log(selectedItem);
        }, function () {
            console.log('Modal dismissed at: ' + new Date());
        });
    }

    validateUpdateCart(event, recordId, maxAmountAllowed) {
        var maxInt = parseInt(maxAmountAllowed);
        var orderQtyInt = parseInt(this.orderQty[recordId]);
        if (orderQtyInt > maxInt) {
            this.errorMessage[recordId] = "Maximum order of " + maxAmountAllowed + " per product allowed.";
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            this.errorMessage[recordId] = "";
        }
    }
    checkoutWithMultiwarehouse() {
        const whModalInstance = this.$uibModal.open({
            templateUrl: 'multiwarehouseMessageBox.html',
            size: 'md',
            windowClass: 'modal-empty-cart',
            controller: 'CartController',
            controllerAs: 'CartCtrl',
        });
        whModalInstance.result.then(function (hasOring) {
            // console.log(selectedItem);
        }, () => {
            console.log('Modal dismissed at: ' + new Date());

        });
    }

    addMap(key, keyVal, canToggle) {
        if (canToggle) {
            if (this.oldSamedayMap[key] != null) {
                this.samedayMap[key] = this.oldSamedayMap[key];
            } else if (this.samedayToggle) {
                this.samedayMap[key] = true;
            }
            else {
                this.samedayMap[key] = keyVal;
            }
            this.saveSamedayToMemory();
        }
    }
    checkoutWithOring() {
        const modalInstance = this.$uibModal.open({
            templateUrl: 'oringMessageBox.html',
            controller: 'CartController',
            controllerAs: 'CartCtrl',
            size: 'md',
            windowClass: 'modal-empty-cart',
        });
        modalInstance.result.then(function (selectedItem) {
            console.log(selectedItem);
        }, function () {
            console.log('Modal dismissed at: ' + new Date());
        });
    }
    ccpMaxQtyMessageBox(message) {
        const modalInstance = this.$uibModal.open({
            templateUrl: 'ccpMaxQtyMessageBox.html',
            controller: 'CartController',
            controllerAs: 'CartCtrl',
            size: 'md',
            windowClass: 'modal-empty-cart',
            resolve: {
                A: function () {
                    return message;
                }
            }
        });
        modalInstance.result.then(function (selectedItem) {
            console.log(selectedItem);
        }, function () {
            console.log('Modal dismissed at: ' + new Date());
        });
    }
    checkoutWithCcpWarning() {
        const modalInstance = this.$uibModal.open({
            templateUrl: 'ccpWarningMessageBox.html',
            controller: 'CartController',
            controllerAs: 'CartCtrl',
            size: 'md',
            windowClass: 'modal-empty-cart',
        });
        modalInstance.result.then(function (selectedItem) {
            console.log(selectedItem);
        }, function () {
            console.log('Modal dismissed at: ' + new Date());
        });
    }
    loadSamedayCart() {
        var loadedValues = JSON.parse(localStorage.getItem('persistantCart'));
        if (loadedValues != null) {
            this.oldSamedayMap = loadedValues;
        }
        var loadedToggle = JSON.parse(localStorage.getItem('persistantToggle'));
        if (loadedToggle != null) {
            this.samedayToggle = loadedToggle;
        }
    }
    showPopupMobile(id) {
        var element = document.getElementsByClassName("popup-active-" + id)[0];
        if (element) {
            element.style.display = "flex";
            this.triggeredElements.push(element);
            this.freezeTrigger = true;
        }
    };
    disableAllMobileElements() {
        if (!this.freezeTrigger) {
            this.triggeredElements.forEach(function (element) {
                element.style.display = "none";
            })
        } else {
            this.freezeTrigger = false;
        }
    }
    toggleSamedayCheckbox() {
        this.samedayToggle = !this.samedayToggle;
        for (const property in this.samedayMap) {
            this.samedayMap[property] = this.samedayToggle;
        }
        this.saveSamedayToggleToMemory();
        this.saveSamedayToMemory();
    }

    toggleCheckbox() {
        this.delay(500).then(() => this.saveSamedayToMemory());
    }

    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    saveSamedayToggleToMemory() {
        localStorage.setItem('persistantToggle', JSON.stringify(this.samedayToggle));
    }

    saveSamedayToMemory() {
        localStorage.setItem('persistantCart', JSON.stringify(this.samedayMap));
    }

    async redirectToCheckout(loadSameDayFromMemory = false, isPartialBool = false, isLeadTime = false) {
        let lTime = Number(localStorage.getItem('offsiteLt'));
        if (loadSameDayFromMemory) {
            this.loadSamedayCart();
            this.samedayMap = this.oldSamedayMap;
        }
        if (this.isPartialOrder() && !isPartialBool) {
            this.removeDisableOverlay();
            const modalInstance = this.$uibModal.open({
                templateUrl: 'partialOrderMessageBox.html',
                controller: 'CartController',
                controllerAs: 'CartCtrl',
                size: 'md',
                windowClass: 'modal-empty-cart'
            });
            modalInstance.result.then(function (selectedItem) {
            }, function () {
            });
        }
        else if (lTime > 0 && !isLeadTime) {
            this.removeDisableOverlay();
            const modalInstance = this.$uibModal.open({
                templateUrl: 'leadTimeMessageBox.html',
                controller: 'CartController',
                controllerAs: 'CartCtrl',
                size: 'md',
                windowClass: 'modal-empty-cart'
            });
            modalInstance.result.then(function (selectedItem) {
            }, function () {
            });
        } else {
            this.displayDisableOverlay();
            let errorResult = await this.sendErrorCheck();
            this.removeDisableOverlay();
            if (errorResult.IsCcptOk && errorResult.CcptMessage.length > 1) {
                this.checkoutWithCcpWarning();
            } else if (errorResult.IsCcptOk) {
                window.location.href = "/Order/Checkout";
            } else {
                localStorage.setItem('ccpOkMessage', errorResult.CcptMessage);
                this.ccpMaxQtyMessageBox();
            }
        }
    }

    displayDisableOverlay() {
        document.getElementById("modal-overlay").style.display = "block";
    }

    removeDisableOverlay() {
        document.getElementById("modal-overlay").style.display = "none";
    }

    async redirectToCheckoutCcpt() {
        window.location.href = "/Order/Checkout";
    }

    async sendErrorCheck() {
        let jsonArray = [];
        for (const property in this.samedayMap) {
            jsonArray.push({
                CartLineId: property,
                SameDayDeliveryString: this.samedayMap[property].toString(),
            })
        }

        const response = await fetch('Cart/UpdateSameDay?sameday=' + JSON.stringify(jsonArray))
        return await response.json();
        //this.$window.location.href = 'Cart/UpdateSameDay?sameday=' + JSON.stringify(jsonArray);
    }

    isPartialOrder() {
        var hasTrue = false;
        var hasFalse = false;
        for (const property in this.samedayMap) {
            if (this.samedayMap[property]) {
                hasTrue = true;
            } else {
                hasFalse = true;
            }
        }
        return (hasTrue && hasFalse);
    }
}