export default class AptToggleChevron {
    constructor() {
        this.restrict = 'A';
    }
    compile(tElement) {
        tElement.on('click', () => {
                  tElement.find('svg')
                   .toggleClass('fa-chevron-down fa-chevron-up');
        });
        //$(".collapse").on('show.bs.collapse', (event) => {
        //    tElement.find('svg')
        //        .removeClass('fa-chevron-down')
        //        .addClass('fa-chevron-up');
        //});
        //$(".collapse").on('hide.bs.collapse', (event) => {
        //    tElement.find('svg')
        //        .removeClass('fa-chevron-up')
        //        .addClass('fa-chevron-down');
       
        //});
    } 
}