export default class ProductDetailsModalController {
    constructor($uibModalInstance, recordId, talkingPointImageURL) {
        this.talkingPointImageURL = talkingPointImageURL.trim().length == 0 ? "/images/placeholders/3000x500.png" : talkingPointImageURL;
        console.log(recordId);
       // console.log(recordId);
    //    ProductService.getProductDetails(recordId).then(productDetails => {
    //  console.log(productDetails);
    //  this.productDetails = productDetails;
    //});
  }
}