import "babel-polyfill";


// Inject styles
//import "./scss/main.scss";

// Inject fonts
import "./font/index";

// Inject js
import "./js/index";
