export default class ProductDetailsController {
  constructor($uibModal) {
    this.$uibModal = $uibModal;
  }

    showDetails(recordId, talkingPointImageURL) {
      const modalInstance = this.$uibModal.open({
        templateUrl: 'productDetailsModalContent.html',
        controller: 'ProductDetailsModalController',
        controllerAs: 'ProductDetailsModalCtrl',
        size: 'xl',
        windowClass: 'modal-product-details',
        resolve: {
          recordId: function () {
            return recordId;
            },
            talkingPointImageURL: function () {
                return talkingPointImageURL;
            }
        }
      });

      modalInstance.result.then(function (selectedItem) {
        console.log(selectedItem);
      }, function () {
        console.log('Modal dismissed at: ' + new Date());
      });
    
  }
};