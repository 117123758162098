export default class UpdatePINModalController {
  constructor(AccountService) {
    this.AccountService = AccountService;
    this.dealerId = "";
    this.emailAddress = "";
    this.sendMessageSucc = false;
  }

  submit() {
    this.AccountService.registerPIN(this.dealerId, this.emailAddress).then(() => {
      console.log(this.dealerId);
      console.log(this.emailAddress);
      this.sendMessageSucc = true;
    });
  }
}