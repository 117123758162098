export default class ProfileController {
  constructor(CustomerService, customerId) {
    console.log(customerId);
    this.CustomerService = CustomerService;
    this.customerId = customerId;
    this.profileDetails = null;
    this.mapLocation = null;
    this.loading = true;
      this.selectDealerButtonPressedClasses = "";
      this.selectDealerButtonPressedSpin = false;

    this.CustomerService.getProfileDetails(customerId).then(profileDetails => {
      console.log(profileDetails);
      this.profileDetails = profileDetails;
      const CompanyGEOLocation = profileDetails.CompanyGEOLocation.split(";").map(item => item.trim());
      console.log(CompanyGEOLocation);
      if (CompanyGEOLocation.length >= 2) {
          this.mapCenter = {
              latitude: CompanyGEOLocation[0],
              longitude: CompanyGEOLocation[1]
          };
          this.mapLocation ={
              latitude: CompanyGEOLocation[0],
              longitude: CompanyGEOLocation[1]
          };
      }
      this.loading = false;
    }, err => {
        if (err.status == 403) {
            window.location = err.data.LogOnUrl;
        }
        });
    }
    selectDealerButtonClicked()
    {
        this.selectDealerButtonPressedClasses = "disabled";
        this.selectDealerButtonSpin = true;
    }
};
