export default class AccountService {
  constructor($http, $q) {
    this.$http = $http;
    this.$q = $q;
  }

  registerPIN(dealerId, emailAddress) {
    const reqObj = {
      "dealerId": dealerId,
      "emailAddress": emailAddress,
    };
    console.info("Send to /Account/ResetPassword data", reqObj);
    return this.$q((resolve, reject) => {
      this.$http.post("/Account/ResetPassword", reqObj).then(
        function success(response) {
          console.info("Response from /Account/ResetPassword", reqObj, response);
          resolve(response);
        }
        ,
        function error(response) {
          console.error("Response from /Account/ResetPassword", reqObj, response);
          reject(response);
        });
    });
  }
}